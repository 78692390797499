* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
}

.app-root {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100svh;
  display: flex;
}

.app-title {
  margin: 15px 0 30px;
}

#app-help {
  z-index: 1;
  border: none;
  max-width: 80%;
  margin: auto;
  position: fixed;
  inset: 0;
}

#app-help::backdrop {
  opacity: .75;
  background-color: #666;
}

.app-help-title {
  margin: 0;
}

.app-help-example {
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  display: flex;
}

.app-help-character {
  display: inline-block;
}

.app-help-form {
  justify-content: center;
  display: flex;
}

.app-help-button {
  cursor: pointer;
  text-transform: uppercase;
  border: none;
  border-radius: 8px;
  width: 100px;
  height: 40px;
  font-size: 16px;
}

.app-help-trigger {
  position: absolute;
  top: 15px;
  right: 15px;
}

.app-help-button:hover, .app-help-button:active {
  background-color: #d0d0d7;
}

.app-help-confirm:focus-visible {
  outline: 2px solid #36c2c2;
}

.mode-selector {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.mode-selector-title {
  margin: 0;
}

.mode-selector-form {
  text-align: center;
  justify-content: space-around;
  width: 300px;
  margin: 30px 0;
  display: flex;
}

.mode-selector-item {
  cursor: pointer;
  text-transform: uppercase;
  background-color: #e9e9ed;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 40px;
  display: flex;
  position: relative;
}

.mode-selector-item:hover {
  background-color: #d0d0d7;
}

.mode-selector-item.selected {
  background-color: #55e25580;
}

.mode-selector-radio {
  opacity: 0;
  cursor: pointer;
  width: 0;
  height: 0;
  position: absolute;
}

.status {
  height: 20px;
  margin-bottom: 30px;
}

.word-guess {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.word-guess-form {
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin-bottom: 30px;
  display: flex;
}

.word-guess-input {
  opacity: 0;
  pointer-events: none;
  position: absolute;
}

.word-display {
  justify-content: space-between;
  gap: 15px;
  display: flex;
}

.character {
  cursor: pointer;
  text-transform: uppercase;
  border: 2px solid #323232;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  display: flex;
}

.character.disabled {
  border-color: #c8c8c8;
}

.focused {
  outline: 2px solid #36c2c2;
}

.valid {
  color: #fff;
  background-color: #caa81d;
}

.correct {
  color: #fff;
  background-color: #44a553;
}

@media screen and (width <= 640px) {
  .app-root {
    justify-content: flex-start;
  }

  .app-title {
    margin: 15px;
    font-size: 24px;
    line-height: 40px;
  }

  .mode-selector-title {
    font-size: 20px;
  }
}
/*# sourceMappingURL=index.23f45b13.css.map */
