* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.app-root {
  height: 100vh;
  height: 100svh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app-title {
  margin: 15px 0 30px;
}

#app-help {
  position: fixed;
  max-width: 80%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  border: none;
  z-index: 1;
}

#app-help::backdrop {
  background-color: #666;
  opacity: 0.75;
}

.app-help-title {
  margin: 0;
}

.app-help-example {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.app-help-character {
  display: inline-block;
}

.app-help-form {
  display: flex;
  justify-content: center;
}

.app-help-button {
  height: 40px;
  width: 100px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  text-transform: uppercase;
}

.app-help-trigger {
  position: absolute;
  top: 15px;
  right: 15px;
}

.app-help-button:hover,
.app-help-button:active {
  background-color: rgb(208, 208, 215);
}

.app-help-confirm:focus-visible {
  outline: 2px solid rgb(54, 194, 194);
}

.mode-selector {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mode-selector-title {
  margin: 0;
}

.mode-selector-form {
  width: 300px;
  display: flex;
  justify-content: space-around;
  text-align: center;
  margin: 30px 0;
}

.mode-selector-item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 100px;
  background-color: rgb(233, 233, 237);
  border-radius: 8px;
  cursor: pointer;
  text-transform: uppercase;
}

.mode-selector-item:hover {
  background-color: rgb(208, 208, 215);
}

.mode-selector-item.selected {
  background-color: rgba(85, 226, 85, 0.5);
}

.mode-selector-radio {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.status {
  height: 20px;
  margin-bottom: 30px;
}

.word-guess {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.word-guess-form {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.word-guess-input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.word-display {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.character {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid rgb(50, 50, 50);
  cursor: pointer;
  font-size: 24px;
  line-height: 24px;
  font-weight: 600;
  text-transform: uppercase;
}

.character.disabled {
  border-color: rgb(200, 200, 200);
}

.focused {
  outline: 2px solid rgb(54, 194, 194);
}

.valid {
  color: #fff;
  background-color: rgb(202, 168, 29);
}

.correct {
  color: #fff;
  background-color: rgb(68, 165, 83);
}

@media screen and (max-width: 640px) {
  .app-root {
    justify-content: flex-start;
  }
  .app-title {
    margin: 15px;
    font-size: 24px;
    line-height: 40px;
  }
  .mode-selector-title {
    font-size: 20px;
  }
}
